
.App-Div{
  background-color: #282c34;
}

.Type-Animation{
  min-height: 100vh;
  position: relative;
  left: 5px;
  font-size: calc(1px + 1vmin);
  color: white;
  font-family: "Sudo";
}
/*
.App {
  text-align: end;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.animation{
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(4px + 2vmin);
  color: white;
  align-self: flex-end;
  margin-top: -5;
  position: absolute;
  font-family: "Sudo";
}*/



